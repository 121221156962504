<template>
  <div
    class="w-full px-4 py-3 text-white flex items-center lg:hidden bg-black justify-between"
  >
    <div class="flex-none">
      <Link
        :href="route('investors.home')"
        class=""
      >
        <img
          src="/images/iv-small-logo.svg"
          alt="Icehouse Ventures Logo"
          class="w-6 h-6"
        >
      </Link>
    </div>

    <div class="flex-grow text-center">
      <h2 class="font-medium tracking-wide">
        {{ title }}
      </h2>
    </div>

    <div class="flex-none">
      <button
        class="w-6 h-6 p-0"
        rounded
        @click="openMenu = !openMenu"
      >
        <i
          v-if="!openMenu"
          class="fa-solid fa-bars mr-10 mt-2"
        />

        <i
          v-if="openMenu"
          class="fa-regular  mr-10 mt-2 fa-xmark"
        />
      </button>
    </div>
  </div>

  <Drawer
    v-model:visible="openMenu"
    class="!bg-black !w-full md:!w-80 lg:!w-[30rem]"
  >
    <template #header>
      <div class="flex i">
        <div class="flex items-center">
          <Link
            :href="route('investors.home')"
            class=""
          >
            <img
              src="/images/iv-small-logo.svg"
              alt="Icehouse Ventures Logo"
              class="w-8 h-8"
            >
          </Link>
          <h2 class="font-medium ml-5 text-zinc-200">
            Menu
          </h2>
        </div>
      </div>
    </template>
    <template #closeicon>
      <i class="fa-solid fa-xmark text-zinc-100" />
    </template>
    <InvestorsNavLinks />
  </drawer>
</template>

<script>

import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import InvestorsNavLinks from "./InvestorsNavLinks.vue";
import PrimeButton from "primevue/button";

import Drawer from "primevue/drawer";


export default {
  components: { InvestorsNavLinks, Accordion, PrimeButton, Drawer},
  data: () => ({
    openMenu: false,
  }),
  computed: {
    title() {
      if(this.route().current("login") || this.route().current("login.*")) {
        return "Login";
      }else if(this.route().current("password.*")) {
        return "Forgot Password";
      }else if(this.route().current("investors.home")) {
        return "Overview";
      }else if(this.route().current("investors.direct.*")) {
        return "Direct";
      }else if(this.route().current("investors.fund.*")) {
        return "Funds";
      }else if(this.route().current("investors.company.*")) {
        return "Companies";
      }else if(this.route().current("investors.transaction.*")) {
        return "Transactions";
      }else if(this.route().current("investors.statement.*")) {
        return "Statements";
      }else if(this.route().current("investors.ranking.*")) {
        return "Benchmarks";
      }else if(this.route().current("investors.offer.*")) {
        return "Offers";
      }

    }
  },

};
</script>
