<template>
  <div v-if="!portalMode">
    <InvestorNav />
    <InvestorTopMobileNav />
  </div>

  <div v-if="portalMode == 'esop'">
    <EsopNav />
    <EsopTopMobileNav />
  </div>

  <div v-if="portalMode == 'founder'">
    <FounderNav />
    <FounderTopMobileNav />
  </div>

  <div v-if="portalMode == 'fund-operator'">
    <FundOperatorNav />
    <FundOperatorMobileNav />
  </div>
</template>

<script>
import FounderTopMobileNav from "@/Shared/Components/Nav/FounderTopMobileNav.vue";
import EsopTopMobileNav from "@/Shared/Components/Nav/EsopTopMobileNav.vue";
import InvestorNav from "@/Shared/Components/Nav/InvestorNav.vue";
import InvestorTopMobileNav from "@/Shared/Components/Nav/InvestorTopMobileNav.vue";
import FounderNav from "@/Shared/Components/Nav/FounderNav.vue";
import EsopNav from "@/Shared/Components/Nav/EsopNav.vue";
import FundOperatorNav from "@/Shared/Components/Nav/FundOperatorNav.vue";
import FundOperatorMobileNav from "@/Shared/Components/Nav/FundOperatorMobileNav.vue";

export default {
  components: {
    FundOperatorMobileNav,
    FundOperatorNav,
    InvestorTopMobileNav,
    InvestorNav,
    EsopNav,
    EsopTopMobileNav,
    FounderNav,
    FounderTopMobileNav,
  },
  props: {
    portalMode: {
      type: String,
      default: null,
    },
  },
};
</script>