<template>
  <div class="bg-white lg:rounded-tl-xl pl-0 md:px-3 border-b border-zinc-200">
    <Menubar
      v-if="$page.props.user"
      class="min-h-10 lg:min-h-20 block md:flex items-center"
    >
      <template #start>
        <slot name="top-bar" />

        <SectionNav
          v-if="sectionLinks?.length > 0"
          :links="sectionLinks"
        />
      </template>

      <template
        v-if="!route().current('investors.investment.*')"
        #end
      >
        <div class="relative hidden lg:flex space-x-4">
          <div class="hidden md:flex space-x-2">
            <EntitiesSelection v-if="$page.props.user?.raw_entities?.length > 1" />
            <AccountMenu />
            <NotificationMenu />
          </div>
        </div>
      </template>
    </Menubar>
  </div>
</template>

<script>

import Menubar from "primevue/menubar";
import SectionNav from "@/Shared/Components/Nav/SectionNav.vue";
import EntitiesSelection from "@/Shared/Components/Elements/EntitiesSelection.vue";
import AccountMenu from "@/Shared/Components/Elements/AccountMenu.vue";
import NotificationMenu from "@/Shared/Components/Elements/NotificationMenu.vue";

export default {
  components: {
    EntitiesSelection,
    SectionNav,
    Menubar,
    AccountMenu,
    NotificationMenu
  },
  props: {
    sectionLinks: Array,
  },
};
</script>