<template>
  <div v-if="$page.props.user">
    <nav
      class="text-white py-1 px-2 lg:hidden fixed bottom-0 left-0 w-full z-50  bg-black/80 backdrop-filter backdrop-blur-md border-t border-zinc-600"
    >
      <div class="grid grid-cols-4 gap-2 text-center ">
        <Link
          :href="route('investors.fund.overview')"
          class="px-3 text-sm flex flex-col items-center text-zinc-400 py-2 mx-1"
          :class="{ 'font-bold !text-white bg-zinc-800 rounded-lg': route().current('investors.fund.*') }"
        >
          <i class="fa-solid fa-layer-group mb-1" />
          Funds
        </Link>
        <Link
          v-if="$page.props.user.has_direct"
          :href="route('investors.direct.overview')"
          class="px-3 text-sm flex flex-col items-center text-zinc-400 py-2 mx-1"
          :class="{ 'font-bold !text-white bg-zinc-800 rounded-lg': route().current('investors.direct.*') }"
        >
          <i class="fa-solid fa-bullseye mb-1" />
          Direct
        </Link>
        <Link
          v-if="!$page.props.user.has_direct"
          :href="route('investors.news.index')"
          class="px-3 text-sm flex flex-col items-center text-zinc-400 py-2 mx-1"
          :class="{ 'font-bold !text-white bg-zinc-800 rounded-lg': route().current('investors.news.index') }"
        >
          <i class="fa-solid fa-newspaper mb-1" />
          News
        </Link>
        <Link
          :href="route('investors.portfolio.index')"
          class="px-3 text-sm flex flex-col items-center text-zinc-400 py-2 mx-1"
          :class="{ 'font-bold !text-white bg-zinc-800 rounded-lg': route().current('investors.company.*') }"
        >
          <i class="fa-solid fa-globe mb-1" />
          Portfolio
        </Link>
        <Link
          :href="route('investors.offer.index')"
          class="px-3 text-sm flex flex-col items-center text-zinc-400 py-2 mx-1"
          :class="{ 'font-bold !text-white bg-zinc-800 rounded-lg': route().current('investors.offer.index') }"
        >
          <i class="fa-solid fa-dollar-sign mb-1" />
          Invest
        </Link>
      </div>
    </nav>
  </div>
</template>
