<template>
  <MultiSelect
    v-model="form.currentEntities"
    :options="$page.props.user.raw_entities"
    option-label="entity_name"
    placeholder="Entities Selection"
    data-key="id"
    input-id="id"
    variant="filled"
    option-value="id"
    :show-toggle-all="false"
    class="text-sm"
    selected-items-label="Entities Selection"
    pt:label:class="p-3 text-zinc-600"
    pt:overlay:class="border border-zinc-200 bg-white rounded-lg text-zinc-600 text-sm mt-2 shadow-lg"
    :max-selected-labels="0"
    :loading="sending"
  >
    <template #header>
      <div class="font-medium px-3 py-2 border-b w-full border-zinc-100">
        Entities
      </div>
    </template>
  </MultiSelect>
</template>
<script>
import MultiSelect from "primevue/multiselect";
import { router } from "@inertiajs/vue3";

export default {
  components: {
    MultiSelect,
  },
  data() {
    return {
      sending: false,
      form: {
        currentEntities: this.$page.props.user ? this.$page.props.user.current_entities : [],
      },
    };
  },

  watch: {
    "form.currentEntities" (currentEntities, previousEntities) {
      if (currentEntities.length === 0) {
        this.form.currentEntities = previousEntities;
      } else {
        this.submit();
      }
    }
  },

  methods: {
    submit () {
      this.sending = true;

      router.post(this.route("investors.settings.store"), this.form, {
        preserveScroll: true,
        onError: (errors) => {
          this.sending = false;
        },
        onSuccess: () => {
          this.sending = false;
        },
      });
    }
  },

};
</script>