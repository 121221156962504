export default {
  root: ({ props, parent }) => ({
    class: [
      // Font
      {
        "text-lg": props.size == "large",
        "text-xl": props.size == "xlarge",
        "text-2xl": props.size == "2xlarge",
        "text-3xl": props.size == "3xlarge",
      },

      // Alignments
      "inline-flex items-center justify-center",
      "relative",

      // Sizes
      {
        "h-8 w-8": props.size == null || props.size == "normal",
        "h-10 w-10": props.size == "medium",
        "w-12 h-12": props.size == "large",
        "w-16 h-16": props.size == "xlarge",
        "w-24 h-24": props.size == "2xlarge",
        "w-32 h-32": props.size == "3xlarge",
      },
      { "-ml-4": parent.instance.$style?.name == "avatargroup" },

      // Shapes
      {
        "rounded-lg": props.shape == "square",
        "rounded-full": props.shape == "circle"
      },
      { "border-2": parent.instance.$style?.name == "avatargroup" },

      // Colors
      "bg-black",
      { "border-white dark:border-surface-800": parent.instance.$style?.name == "avatargroup" }
    ]
  }),
  image: ({ props }) => ({
    class: [
      "h-full w-full",
      {
        "rounded-lg": props.shape == "square",
        "rounded-full": props.shape == "circle"
      }
    ]
  })
};
