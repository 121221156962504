<template>
  <nav
    v-if="$page.props.user"
    class="flex-1 mt-1 h-full"
  >
    <Link
      :href="route('raise.company')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('raise.company') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fa-solid fa-house mr-3" /> Dashboard
    </Link>

    <Link
      :href="route('raise.offer.index' )"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('raise.offer.*') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fas fa-hand-holding-usd mr-3" /> Raise Capital
    </Link>


    <Link
      :href="route('company.metrics.index')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('company.metrics.index') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fa-solid fa-chart-line-up-down mr-3" /> Metrics
    </Link>

    <Link
      :href="route('company.benchmarks.charts')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('company.benchmarks.charts') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fas fa-analytics mr-3" /> Benchmarks
    </Link>

    <Link
      :href="route('registry.index')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('registry.*') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fa-solid fa-chart-pie mr-3" /> Cap Table
    </Link>

    <Link
      :href="route('esop.management.overview')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('esop.management.*') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fa-solid fa-users mr-3" /> ESOP Management
    </Link>

    <hr class="w-52 my-2 h-[0.5px] mx-auto border-0 bg-white bg-opacity-[0.2] mb-5">

    <Link
      :href="route('investors.home')"
      :class="{ 'font-medium border-white': route().current('investors.home') }"
      class="group flex items-center justify-center text-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 bg-zinc-900 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-zinc-800 hover:border-white p-3 transition-all ease-in-out"
    >
      My Investments
    </Link>

    <div v-if="$page.props.user.has_fund_operator">
      <Link
        :href="route('operator.overview')"
        :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('operator.*') }"
        class="group flex items-center justify-center text-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 bg-zinc-900 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-zinc-800 hover:border-white p-3 transition-all ease-in-out"
      >
        Manage Fund
      </Link>
    </div>

    <div v-if="$page.props.user?.is_admin && !$page.props.user?.is_impersonating">
      <a
        href="/admin"
        :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('operator.*') }"
        class="group flex items-center justify-center text-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 bg-zinc-900 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-zinc-800 hover:border-white p-3 transition-all ease-in-out"
      >
        Staff Portal
      </a>
    </div>

    <div v-if="$page.props.user.is_esop_participant">
      <Link
        :href="route('esop.home')"
        :class="{ 'font-medium border-white': route().current('investors.settings') }"
        class="group flex items-center justify-center text-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 bg-zinc-900 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-zinc-800 hover:border-white p-3 transition-all ease-in-out"
      >
        Equity
      </Link>
    </div>

    <div
      v-if="$page.props.user?.is_impersonating"
      class="p-2 bg-red-900/20  rounded-lg text-zinc-200 text-center border border-red-900/30 absolute bottom-2 left-2 right-2"
    >
      <i class="fa-solid fa-user-secret text-zinc-600" /> <span class="ml-1"> {{ $page.props.user.name }}</span>
      <div class="flex space-x-2 justify-center">
        <a
          :href="route('impersonate.stop', { destination: 'admin.home' })"
          class="group flex items-center justify-center text-center text-sm  my-1 text-zinc-400 bg-red-800/30 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-red-800 hover:border-white p-3 transition-all ease-in-out"
        >
          Stop Impersonating
        </a>
        <a
          :href="route('impersonate.random')"
          class="group flex items-center justify-center text-center text-sm  my-1 text-zinc-400 bg-red-800/30 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-red-800 hover:border-white p-3 transition-all ease-in-out"
        >
          <i class="fa-solid fa-arrows-rotate " />
        </a>
      </div>
    </div>
  </nav>
</template>
<script setup lang="ts">
</script>