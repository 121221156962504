<template>
  <nav
    v-if="$page.props.user"
    class="flex-1 mt-1 h-full"
  >
    <Link
      prefetch
      :href="route('investors.home')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.home') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fa-solid fa-house mr-3" /> Dashboard
    </Link>

    <Link
      prefetch
      :href="route('investors.fund.overview')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.fund.*') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-layer-group mr-3" /> Fund Investments
    </Link>

    <Link
      v-if="$page.props.user.has_direct"
      prefetch
      :href="route('investors.direct.overview')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.direct.*') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-bullseye mr-3" /> Direct Investments
    </Link>

    <Link
      v-if="$page.props.user.has_custodial"
      :href="route('investors.custodial.overview')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.custodial.*') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-chart-pie-simple mr-3" /> Custodial
    </Link>

    <Link
      prefetch
      :href="route('investors.portfolio.index')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': (route().current('investors.portfolio.*') || route().current('investors.company.*')) && !route().current('investors.company.benchmarks.*') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md relative"
    >
      <i class="fa-solid fa-globe mr-3" /> Portfolio
      <div
        v-if="$page.props.user.navigation_highlight_portfolio"
        class="ml-2 w-1.5 h-1.5 bg-blue-500 rounded-full"
      />
    </Link>

    <Link
      prefetch
      :href="route('investors.transaction.index')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.transaction.*') && !route().current('investors.company.metrics.*') && !route().current('investors.company.benchmarks.*') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-regular fa-arrow-right-arrow-left mr-3" /> Transactions
    </Link>

    <Link
      prefetch
      :href="route('investors.offer.index')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.offer.*') || route().current('investors.indication*') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-dollar-sign mr-4 ml-1" /> Invest
    </Link>

    <Link
      prefetch
      :href="route('investors.statement.generator')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.statement.*') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-file-invoice mr-3" /> Statements
    </Link>
    <Link
      prefetch
      :href="route('investors.benchmarks.charts')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.benchmarks.*') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-chart-mixed mr-3" /> Analysis
    </Link>

    <Link
      prefetch
      :href="route('investors.news.index')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('investors.news.*') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-newspaper mr-3" /> News
    </Link>

    <Link
      prefetch
      :href="route('investors.settings')"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-gear mr-3" /> Settings
    </Link>

    <Link
      :href="route('logout')"
      method="post"
      as="button"
      class="group flex lg:hidden items-center mx-1 md:mx-4 w-full text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md "
    >
      <i class="fa-solid fa-arrow-right-to-bracket mr-3" /> Logout
    </Link>

    <hr class="w-52 my-2 h-[0.5px] mx-auto  border-0 bg-white bg-opacity-[0.2] mb-5">

    <!-- User Mode Context Buttons-->

    <div v-if="$page.props.user.has_raise_company">
      <Link
        prefetch
        :href="route('raise.company')"
        :class="{ '!font-medium !text-white bg-zinc-800 p-3 ': route().current('raise.*') }"
        class="group flex items-center justify-center text-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 bg-zinc-900 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-zinc-800 hover:border-white p-3 transition-all ease-in-out"
      >
        Manage Company
      </Link>
    </div>

    <div v-if="$page.props.user.has_fund_operator">
      <Link
        prefetch
        :href="route('operator.overview')"
        :class="{ '!font-medium !text-white bg-zinc-800 p-3': route().current('operator.*') }"
        class="group flex items-center justify-center text-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 bg-zinc-900 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-zinc-800 hover:border-white p-3 transition-all ease-in-out"
      >
        Manage Fund
      </Link>
    </div>

    <div v-if="$page.props.user?.is_admin && !$page.props.user?.is_impersonating">
      <a
        href="/admin"
        class="group flex items-center justify-center text-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 bg-zinc-900 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-zinc-800 hover:border-white p-3 transition-all ease-in-out"
      >
        Staff Portal
      </a>
    </div>

    <div
      v-if="$page.props.user?.is_impersonating"
      class="p-2 bg-red-900/20  rounded-lg text-zinc-200 text-center border border-red-900/30 absolute bottom-2 left-2 right-2"
    >
      <i class="fa-solid fa-user-secret text-zinc-600" /> <span class="ml-1"> {{ $page.props.user.name }}</span>
      <div class="flex space-x-2 justify-center">
        <a
          :href="route('impersonate.stop', { destination: 'admin.home' })"
          class="group flex items-center justify-center text-center text-sm  my-1 text-zinc-400 bg-red-800/20 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-red-800 hover:border-white p-3 transition-all ease-in-out"
        >
          Stop Impersonating
        </a>
        <a
          :href="route('impersonate.random')"
          class="group flex items-center justify-center text-center text-sm  my-1 text-zinc-400 bg-red-800/20 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-red-800 hover:border-white p-3 transition-all ease-in-out"
        >
          <i class="fa-solid fa-arrows-rotate " />
        </a>
      </div>
    </div>
  </nav>
  <nav v-else>
    <Link
      :href="route('login')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 ': route().current('login') }"
      class="group flex items-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fa-regular fa-right-to-bracket mr-3" /> Login
    </Link>
  </nav>
</template>
