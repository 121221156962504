<template>
  <button
    class="text-sm p-3 text-zinc-600 leading-none inline-flex relative rounded-lg shadow-sm border border-surface-300 transition-all duration-200 hover:border-surface-400  cursor-pointer"
    @click="togglAccountMenu"
  >
    Account
    <i class="fa-regular fa-chevron-down ml-2 mr-1" />
  </button>

  <PrimeMenu
    id="account_menu"
    ref="accountMenu"
    :model="items"
    :popup="true"
    :show-transition-options="menuOptions"
    class="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg z-50 overflow-hidden"
  >
    <template #start>
      <span class="inline-flex items-center p-2 text-sm border-b w-full border-zinc-100 overflow-hidden">
        <Avatar
          :label="$page.props.user.name.charAt(0)"
          class="mr-2 min-w-12"
          size="large"
          style="background-color: rgba(43, 157, 143, 0.1); color: #2B9D8F; border: 0.01px solid #2B9D8F;"
          shape="circle"
        />
                  
        <div class="text-sm flex flex-col overflow-hidden">
          <span class="font-medium">{{ $page.props.user.name }}</span>
          <span class="text-zinc-400 text-xs ">{{ $page.props.user.email }}</span>
        </div>
      </span>
    </template>

    <template #item="{ item, props }">
      <div
        v-if="item.hr"
        class="w-full h-px bg-zinc-100"
      />
      <template v-else>
        <Link
          v-if="item.route"
          v-bind="props.action"
          class="flex items-center"
          :href="item.route"
        >
          <i :class="props.icon.class" />
          <span class="ml-2 text-sm">{{ item.label }}</span>
        </Link>
        <a
          v-else
          v-bind="props.action"
          @click="item.command"
        >
          <i :class="props.icon.class" />
          <span class="ml-2 text-sm">{{ item.label }}</span>
        </a>
      </template>
    </template>
  </PrimeMenu>
</template>

<script>
import PrimeMenu from "primevue/menu";
import Avatar from "primevue/avatar";

export default {
  components: {
    PrimeMenu,
    Avatar
  },

  data() {
    return {
      menuOptions: "opacity: 0.75",
      items: [
        {
          label: "Compliance",
          icon: "fa-solid fa-fingerprint",
          route: route("investors.compliance.dashboard")
        },
        {
          label: "Settings",
          icon: "fa-regular fa-gear",
          route: route("investors.settings")
        },
        {
          hr: true,
        },
        {
          label: "Support",
          icon: "fa-solid fa-user-headset",
          command: () => {
            window.HubSpotConversations.widget.open();
          },
        },
        {
          hr: true,
        },
        {
          label: "Sign Out",
          icon: "fa-solid fa-arrow-right-to-bracket",
          command: () => {
            this.$inertia.post(route("logout"), {
            });
          },
        }     
      ]
    };
  },

  methods: {
    togglAccountMenu(event) {
      this.$refs.accountMenu.toggle(event);
    }
  }
};
</script>
