<template>
  <nav
    v-if="$page.props.user"
    class="flex-1 mt-1 h-full"
  >
    <Link
      prefetch
      :href="route('operator.overview')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('operator.overview') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fa-solid fa-house mr-3" /> Dashboard
    </Link>

    <Link
      prefetch
      :href="route('operator.funds' )"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('operator.funds') || route().current('operator.fund.view') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fas fa-layer-group mr-3" /> Funds
    </Link>


    <Link
      prefetch
      :href="route('operator.transactions')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('operator.transactions') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fa-solid fa-arrow-right-arrow-left mr-3" /> Transactions
    </Link>

    <Link
      prefetch
      :href="route('operator.allocations')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('operator.allocations') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fa-solid fa-badge-percent mr-3" /> Allocations
    </Link>

    <Link
      prefetch
      :href="route('operator.companies')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('operator.companies') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fa-solid fa-globe mr-3" /> Companies
    </Link>

    <Link
      prefetch
      :href="route('operator.documents')"
      :class="{ '!font-medium !text-white bg-zinc-800 p-3 rounded-md': route().current('operator.documents') }"
      class="group flex items-center mx-4 text-sm my-1 text-zinc-400 focus:outline-none hover:bg-zinc-800 p-3 rounded-md"
    >
      <i class="fa-solid fa-file-invoice mr-3" /> Documents
    </Link>

    <hr class="w-52 my-2 h-[0.5px] mx-auto border-0 bg-white bg-opacity-[0.2] mb-5">
    <Link
      prefetch
      :href="route('investors.home')"
      :class="{ 'font-medium border-white': route().current('investors.home') }"
      class="group flex items-center justify-center text-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 bg-zinc-900 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-zinc-800 hover:border-white p-3 transition-all ease-in-out"
    >
      My Investments
    </Link>

    <div v-if="$page.props.user.has_raise_company">
      <Link
        prefetch
        :href="route('raise.company')"
        :class="{ '!font-medium !text-white bg-zinc-800 p-3 ': route().current('raise.*') }"
        class="group flex items-center justify-center text-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 bg-zinc-900 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-zinc-800 hover:border-white p-3 transition-all ease-in-out"
      >
        Manage Company
      </Link>
    </div>

    <div v-if="$page.props.user?.is_admin && !$page.props.user?.is_impersonating">
      <a
        href="/admin"
        class="group flex items-center justify-center text-center mx-1 md:mx-4 text-sm  my-1 text-zinc-400 bg-zinc-900 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-zinc-800 hover:border-white p-3 transition-all ease-in-out"
      >
        Staff Portal
      </a>
    </div>

    <div
      v-if="$page.props.user?.is_impersonating"
      class="p-2 bg-red-900/20  rounded-lg text-zinc-200 text-center border border-red-900/30 absolute bottom-2 left-2 right-2"
    >
      <i class="fa-solid fa-user-secret text-zinc-600" /> <span class="ml-1"> {{ $page.props.user.name }}</span>
      <div class="flex space-x-2 justify-center">
        <a
          :href="route('impersonate.stop', { destination: 'admin.home' })"
          class="group flex items-center justify-center text-center text-sm  my-1 text-zinc-400 bg-red-800/30 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-red-800 hover:border-white p-3 transition-all ease-in-out"
        >
          Stop Impersonating
        </a>
        <a
          :href="route('impersonate.random')"
          class="group flex items-center justify-center text-center text-sm  my-1 text-zinc-400 bg-red-800/30 rounded-lg focus:outline-none hover:text-zinc-200 hover:bg-red-800 hover:border-white p-3 transition-all ease-in-out"
        >
          <i class="fa-solid fa-arrows-rotate " />
        </a>
      </div>
    </div>
  </nav>
</template>