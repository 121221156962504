<template>
  <div class="flex items-center">
    <button
      class="text-sm p-3  text-zinc-600 leading-none inline-flex relative transition-all duration-200 hover:border-surface-400  cursor-pointer"
      @click="toggleNotificationMenu"
    >
      <i class="fa-regular fa-bell" />
      <span
        v-if="unreadNotifications.length > 0"
        class="absolute top-1 right-1 bg-blue-500 text-white rounded-full text-xs w-2 h-2 flex items-center justify-center"
      />
    </button>

    <PrimeMenu
      id="notification_menu"
      ref="notificationMenu"
      :model="notifications"
      :popup="true"
      :show-transition-options="menuOptions"
      class="absolute right-0 mt-2 w-96 bg-white rounded-lg shadow-lg z-50"
      pt:list:class="mx-2 max-h-96 overflow-y-auto"
      pt:item:class="!hover:bg-blue-50"
    >
      <template #start>
        <div class="flex items-center justify-between w-full py-2">
          <span class="inline-flex items-center p-2 text-sm  font-medium">
            Notifications
          </span>
          <PrimeButton
            v-if="unreadNotifications.length > 0"
            link
            size="small"
            label="Mark all as read"
            @click="markAllAsRead"
          />
        </div>

        <div class="flex border rounded-lg relative bg-zinc-100/80 text-sm mx-2 mt-2 transition-all duration-200 mb-5">
          <div
            class="w-1/2 py-2 flex items-center justify-center relative z-10 cursor-pointer font-medium text-zinc-500"
            :class="{ 'bg-white rounded-lg text-zinc-600': activeTab === 'all' }"
            @click="activeTab = 'all'"
          >
            <div
              v-if="activeTab === 'all'"
              class="absolute top-0 left-0 right-0 bottom-0 border border-zinc-300 rounded-lg -m-px"
            />

            <span>View all</span>
            <span
              v-if="unreadNotifications.length > 0"
              class="ml-2 bg-blue-50 text-blue-600 px-2 py-0.5 rounded-md text-xs"
            >
              {{ unreadNotifications.length }}
            </span>
          </div>

          <div
            class="w-1/2 py-2 flex items-center justify-center relative z-10 cursor-pointer font-medium text-zinc-5 t00"
            :class="{ 'bg-white rounded-lg text-zinc-600': activeTab === 'unread' }"
            @click="activeTab = 'unread'"
          >
            <div
              v-if="activeTab === 'unread'"
              class="absolute top-0 left-0 right-0 bottom-0 border border-zinc-300 rounded-lg -m-px"
            />

            <div
              class="flex items-center justify-center relative"
            >
              <span>Unread</span>
            </div>
          </div>
        </div>
      </template>

      <template #item="{ item, props }">
        <Link :href="item.link">
          <div class="flex items-start text-sm border-b border-zinc-100">
            <div class="flex justify-center items-center mx-2 pt-5">
              <Avatar
                v-if="item.company"
                :image="item.company?.logo"
                class="mr-2"
                size="medium"
              />
              <Avatar
                v-else
                image="/images/iv-small-logo.svg"
                size="medium"
                class="mr-2 p-1"
              />
            </div>
            <div class="py-3 pr-4 relative">
              <div
                v-if="!item.read"
                class="absolute top-2 right-3 w-2 h-2 bg-blue-500 rounded-full"
              />
              <div class="font-medium">
                {{ item.headline }}
              </div>
              <div class="mt-1 text-light text-xs text-zinc-600">
                {{ item.contents }}
              </div>

              <div class="text-zinc-400 text-xs mt-1">
                {{ $moment(item.created_at).fromNow() }}
              </div>
            </div>
          </div>
        </Link>
      </template>

      <template #end>
        <div
          v-if="notifications.length === 0"
          class="flex items-center justify-center py-5 mx-2 text-zinc-400 text-sm"
        >
          <span v-if="activeTab === 'all'">
            You have no notifications.
          </span>
          <span v-else>
            You have no unread notifications.
          </span>
        </div>
        <div class="flex items-center justify-between py-2 mx-2 border-t border-zinc-100">
          <Link :href="route('investors.settings')">
            <span class="inline-flex items-center p-2 text-sm font-medium">
              Settings
            </span>
          </Link>

          <Link
            v-if="$page.props.user.notifications.length > 0"
            :href="route('investors.news.notifications')"
          >
            <PrimeButton
              size="small"
              rounded=""
              severity="contrast"
              label="View all"
            />
          </Link>
        </div>
      </template>
    </Primemenu>
  </div>
</template>

<script>
import PrimeMenu from "primevue/menu";
import PrimeButton from "primevue/button";
import Avatar from "primevue/avatar";

export default {
  components: {
    PrimeMenu,
    PrimeButton,
    Avatar
  },

  data() {
    return {
      menuOptions: "opacity: 0.75",
      activeTab: "all",
    };
  },
  computed: {
    notifications() {
      if (this.activeTab === "all") {
        return this.$page.props.user.notifications ?? [];
      } else {
        return this.$page.props.user.notifications?.filter(notification => notification.read === false) ?? [];
      }
    },
    unreadNotifications() {
      return this.$page.props.user.notifications?.filter(notification => notification.read === false) ?? [];
    }
  },
  methods: {
    toggleNotificationMenu(event) {
      this.$refs.notificationMenu.toggle(event);
    },
    markAllAsRead() {
      this.$inertia.post(
        route("investors.news.notifications.read"), {}, {
          preserveScroll: true,
          onSuccess: () => {
            this.activeTab = "all";
          },
        }
      );
    }
  }
};

</script>